import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { isLoggedIn } from "../services/auth"
import Login from './login'

import pic01 from '../assets/images/instructions-pomegranate-01.jpg'
import pic02 from '../assets/images/instructions-pomegranate-02.jpg'
import pic03 from '../assets/images/instructions-pomegranate-03.jpg'
import pic04 from '../assets/images/instructions-pomegranate-04.jpg'
import pic05 from '../assets/images/instructions-pomegranate-05.jpg'
import pic06 from '../assets/images/instructions-pomegranate-06.jpg'

const Pomegranates = (props) => {
    if (!isLoggedIn()) {
        return <Login />
    }
    return (
        <Layout>
            <Helmet>
                <title>Pomegranates - Moonlight</title>
                <meta name="description" content="Pomegranates" />
            </Helmet>

            <BannerLanding
                bannerStyle="style3"
                headerText="Pomegranates are the Jewel of Autumn"
                descriptionText="With its distinctive crown, multitude of ruby red arils, and a big, bold appearance, there’s no mistaking the pomegranate."
            />

            <div id="main">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h2>Perfect With Almost Any Fall Dish</h2>
                        </header>
                        <p>Cultivated for centuries, the pomegranate has been a symbol of abundance and prosperity dating back to 3500 BC. Not only are they delicious, many people harness the beauty of this fruit for use in crafts and home decor.</p>
                        <p>Moonlight pomegranates are picked at the peak of ripeness and are available from late September to early December.</p>
                    </div>
                </section>
                <section id="two" className="spotlights">
                    <section>
                        <div className="image">
                            <img src={pic02} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>CUT, PRY, LOOSEN, EAT!</h3>
                                </header>
                                <p>AN EASY WAY TO GET THE ARILS OUT OF A POMEGRANATE</p>
                                <ol>
                                    <li>
                                        <p>Cut off the top, score along each section of the pomegranate.</p>
                                        <span className="image"><img className="bordered-image" src={pic03} alt="" /></span>
                                    </li>
                                    <li>
                                        <p>Gently pry open the pomegranate.</p>
                                        <span className="image"><img className="bordered-image" src={pic04} alt="" /></span>
                                    </li>
                                    <li>
                                        <p>Loosen the arils from the white membrane.</p>
                                        <span className="image"><img className="bordered-image" src={pic05} alt="" /></span>
                                    </li>
                                    <li>
                                        <p>Place in strainer or colander. Enjoy!</p>
                                        <span className="image"><img className="bordered-image" src={pic06} alt="" /></span>
                                    </li>
                                </ol>
                                <h5>Storage and Handling Tips:</h5>
                                <p>
                                    Whole pomegranates can be stored for a month in a cool, dry area or refrigerated up to two months. Arils can be frozen in an airtight bag for up to one year. Fresh juice should be refrigerated and used within two to three days.
                            </p>
                                <ul className="actions">
                                    <li><Link to="/#pomegranates" className="button">Back</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>

        </Layout>
    )
}

export default Pomegranates